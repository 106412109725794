import { useMemo } from 'react'
import classnames from 'classnames'

import { useLocale } from 'src/hooks/use-locale'
import { Button } from '../atoms/button/Button'
import { CtaStripe, ImageCms, RatingCms, TextDocument } from '.'
import {
  ANALYTICS_CATEGORIES,
  ANALYTICS_KEYS,
  ANALYTICS_LABEL,
  computeAnalyticsTrackEvent,
} from 'src/utils/analytics'

export const HeroCms = ({
  title,
  subtitle,
  rating,
  cta,
  desktopImage,
  mobileImage,
  ctaLabel,
  displayType,
}: any) => {
  const { isDefaultLocale } = useLocale()

  const handleClick = () => {
    computeAnalyticsTrackEvent(
      ANALYTICS_KEYS.SUBSCRIPTION_CLICK_SUBSCRIBE,
      ANALYTICS_CATEGORIES.SUBSCRIPTION,
      { name: ANALYTICS_LABEL.SUBSCRIPTION_CLICK_SUBSCRIBE_HOME_BANNER },
    )
  }

  const renderCta = useMemo(() => {
    switch (cta?.__typename) {
      case 'Cta':
        return (
          <Button
            title={cta.label}
            href={isDefaultLocale ? `/${cta.url}` : '/'}
            buttonType={cta.displayType}
            onClick={handleClick}
          >
            {cta.label}
          </Button>
        )

      case 'CtaStripe':
        return (
          <CtaStripe
            label={cta.label}
            priceId={cta.priceId}
            promoCode={cta.promoCode}
            trialPeriod={cta.trialPeriod}
          />
        )

      default:
        return null
    }
  }, [cta, isDefaultLocale])

  return (
    <div>
      <div
        className={classnames(
          'flex flex-col justify-center items-center md:items-start p-5 pt-0 md:p-0 relative',
          {
            'md:min-h-585': displayType === 'normal',
            'md:min-h-390': displayType !== 'normal',
          },
        )}
      >
        {((desktopImage?.url && mobileImage?.url) || desktopImage?.url) && (
          <ImageCms
            className={classnames('absolute h-60 inset-0 -z-1 w-full hero', {
              'md:min-h-585': displayType === 'normal',
              'md:min-h-390': displayType !== 'normal',
            })}
            imageClassName="w-full h-full object-cover object-right-top md:object-top"
            src={{
              landscape: desktopImage.url,
              portrait: mobileImage?.url || desktopImage?.url,
            }}
            alt="Home_Banner_Picture"
          />
        )}

        <div className="flex flex-col justify-center items-center md:items-start md:ml-6vw lg:ml-24 md:max-w-xl space-y-5">
          <h1
            className={classnames('pt-36 md:pt-0 font-black text-center md:text-left', {
              'text-3xl md:text-6xl': displayType === 'normal',
              'text-3xl md:text-4xl': displayType !== 'normal',
            })}
          >
            {title}
          </h1>
          {subtitle && <TextDocument className="text-center md:text-left" text={subtitle.json} />}
          {(ctaLabel || cta) && (
            <div className="flex flex-col items-center justify-center pt-10 md:flex-row md:justify-start w-full">
              {isDefaultLocale && ctaLabel && (
                <p className="pb-3 text-white text-xl font-medium md:pb-0 md:pr-12">{ctaLabel}</p>
              )}
              {cta && <div>{renderCta}</div>}
            </div>
          )}
          {isDefaultLocale && rating && <RatingCms ratingData={rating} />}
        </div>
      </div>
    </div>
  )
}
