import React from 'react'

import { useRouter } from 'next/router'

import { Button } from '../atoms/button/Button'
import { useOffer } from '../providers/OfferProvider'
import {
  ANALYTICS_CATEGORIES,
  ANALYTICS_KEYS,
  computeAnalyticsTrackEvent,
} from 'src/utils/analytics'

export type CtaStripeProps = Omit<any, '__typename'> & {
  fullWidth?: boolean
}

export const CtaStripe = ({
  label,
  priceId,
  promoCode,
  trialPeriod,
  fullWidth = false,
}: CtaStripeProps) => {
  const { asPath } = useRouter()
  const { addCheckoutElement } = useOffer()

  const handleClick = () => {
    addCheckoutElement({
      items: [{ id: priceId }],
      code: promoCode,
      trialPeriod,
      redirectUrl: asPath,
    })

    computeAnalyticsTrackEvent(
      ANALYTICS_KEYS.SUBSCRIPTION_ADD_CART,
      ANALYTICS_CATEGORIES.SUBSCRIPTION,
      { priceId },
    )
  }

  return (
    <Button onClick={handleClick} data-testid={priceId} fullWidth={fullWidth}>
      {label}
    </Button>
  )
}
