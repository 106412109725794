import { PropsWithChildren } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { IntlProvider as ReactIntlProvider } from 'react-intl'
import 'moment/locale/fr'

import frMessages from '../../intl/fr.json'
import enMessages from '../../intl/en.json'

const messages = {
  fr: frMessages,
  en: enMessages,
}

const defaultRichTextElements = {
  icon: (children: any) => `<icon name="${children}"></icon>`,
  b: (children: any) => <b>{children}</b>,
  p: (children: any) => `<p>${children}</p>`,
  ul: (children: any) => `<ul>${children}</ul>`,
  li: (children: any) => `<li>${children}</li>`,
  terms: (children: any) => (
    <Link href="/terms-service">
      <a className="font-bold text-white">{children}</a>
    </Link>
  ),
  br: () => <br />,
  legals: (children: any) => `<a href="">${children}</a>`,
  policy: (children: any) => (
    <Link href="/privacy-policy">
      <a className="font-bold text-white">{children}</a>
    </Link>
  ),
  help: (children: any) => `<a href="">${children}</a>`,
  support: (children: any) => `<a href="">${children}</a>`,
  download: (children: any) => `<a href="">${children}</a>`,
  giftTerms: (children: any) => `<a class='link' href="" target="_blank">${children}</a>`,
  cookies: (children: any) => `<button onclick="OneTrust.ToggleInfoDisplay()">${children}</button>`,
}

interface IntlProviderProps {}

export const IntlProvider = ({ children }: PropsWithChildren<IntlProviderProps>) => {
  const { locale } = useRouter()

  return (
    <ReactIntlProvider
      key={locale}
      locale={locale}
      messages={messages[locale]}
      defaultRichTextElements={defaultRichTextElements}
    >
      {children}
    </ReactIntlProvider>
  )
}
