import React, { PropsWithChildren } from 'react'
import { QueryClientProvider, QueryClient } from 'react-query'

export const queryClient = new QueryClient()

interface NetworkProviderProps {}

export const NetworkProvider = ({ children }: PropsWithChildren<NetworkProviderProps>) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
)
