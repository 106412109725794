import { ImageCms } from '.'

export interface NewsListProps {
  imageCollection: any //AssetContentful[]
}

export const NewsListCms = ({ imageCollection }: NewsListProps) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex justify-center items-center flex-wrap">
        {imageCollection.map((news, key) => (
          <ImageCms
            className="m-5 max-w-90 max-h-8 md:max-h-12 object-contain"
            src={news.url}
            alt={news.title}
            key={`news-list-${key}`}
            width={news.width}
            height={news.height}
            contentType={news.contentType}
          />
        ))}
      </div>
    </div>
  )
}
