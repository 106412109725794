import classnames from 'classnames'
import React from 'react'

interface TextProps {
  text: string
  className?: string
}

export const PrimaryTitle = ({ text, className }: TextProps) => (
  <h1
    className={classnames(
      'text-2-5xl md:text-4-5xl font-bold text-white',
      className,
    )}
  >
    {text}
  </h1>
)

export const SecondaryTitle = ({ text, className }: TextProps) => (
  <h2 className={classnames('text-2xl font-bold text-white', className)}>
    {text}
  </h2>
)

export const TertiaryTitle = ({ text, className }: TextProps) => (
  <h3 className={classnames('text-xl font-bold text-white', className)}>
    {text}
  </h3>
)

export const FourthTitle = ({ text, className }: TextProps) => (
  <h4
    className={classnames(
      'text-sm font-normal uppercase text-white',
      className,
    )}
  >
    {text}
  </h4>
)

export const Paragraph = ({ text, className }: TextProps) => (
  <p className={classnames('text-lg font-normal text-cloud', className)}>
    {text}
  </p>
)
