import React, { useEffect } from 'react'
import { AppProps as NextAppProps } from 'next/app'
import 'resize-observer-polyfill/dist/ResizeObserver.global'

import { Head } from '../src/components/molecules/header/Head'
import { NotificationsList } from '../src/components/molecules/NotificationList'
import { AppProviders } from '../src/components/providers/AppProviders'
import { Scripts } from 'src/components/Scripts'

import '../styles/global.css'

interface AppProps extends NextAppProps {}

const App = ({ Component, pageProps, router }: AppProps) => {
  const { metas } = pageProps || {}
  const { asPath } = router

  useEffect(() => {
    const handleRouteComplete = (url: string = '') => {
      // @ts-ignore
      window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
        page_path: url,
      })
    }

    router.events.on('routeChangeComplete', handleRouteComplete)
    handleRouteComplete(router?.asPath)

    return () => {
      router.events.off('routeChangeComplete', handleRouteComplete)
    }
  }, [router.events, router.asPath])

  return (
    <AppProviders>
      <Head metas={metas} asPath={asPath} />
      <Scripts />
      <main>
        <NotificationsList />
        <Component {...pageProps} />
      </main>
    </AppProviders>
  )
}

export default App
