import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'

export interface TextDocumentProps {
  text: any
  className?: string
}

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: any, children: any) => (
      <p className="text-lg font-normal text-cloud pb-10 last:p-0">{children}</p>
    ),
    [BLOCKS.HEADING_1]: (node: any, children: any) => (
      <h1 className="text-2-5xl md:text-4-5xl font-bold text-white pb-3 last:p-0">{children}</h1>
    ),
    [BLOCKS.HEADING_2]: (node: any, children: any) => (
      <h2 className="text-2xl font-bold text-white pb-3 last:p-0">{children}</h2>
    ),
    [BLOCKS.HEADING_3]: (node: any, children: any) => (
      <h3 className="text-xl font-bold text-white pb-3 last:p-0">{children}</h3>
    ),
    [BLOCKS.HEADING_4]: (node: any, children: any) => (
      <h4 className="text-sm font-normal uppercase pb-3 last:p-0 text-white">{children}</h4>
    ),
    [INLINES.HYPERLINK]: (node: any, children: any) => (
      <a
        className="text-white underline"
        href={node.data.uri}
        target="_blank"
        rel="noreferrer nofollow"
      >
        {children}
      </a>
    ),
    [BLOCKS.UL_LIST]: (node: any, children: any) => <ul className="list-disc pl-4">{children}</ul>,
    [BLOCKS.OL_LIST]: (node: any, children: any) => (
      <ol className="list-decimal pl-7">{children}</ol>
    ),
  },
  renderMark: {
    [MARKS.BOLD]: (children: any) => <b className="font-bold text-white">{children}</b>,
  },
}

export const TextDocument = ({ text, className }: TextDocumentProps) => (
  <div className={className}>{documentToReactComponents(text, options)}</div>
)
