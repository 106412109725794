import { ImageCms } from './ImageCms'
import { TertiaryTitle } from './title'

type ImageBannerProps = {
  title: string
  imageDesktop: any //AssetContentful
  imageMobile: any //AssetContentful
}

export const ImageBanner = ({ title, imageDesktop, imageMobile }: ImageBannerProps) => (
  <div className="px-3 md:px-0 pt-10 pb-20 bg-palombe">
    <div>
      <div className="max-w-screen-lg m-auto space-y-10">
        <TertiaryTitle text={title} className="text-center px-10 md:px-0" />
        <ImageCms
          imageClassName="w-full m-auto"
          src={{ landscape: imageDesktop.url, portrait: imageMobile.url }}
          contentType={imageDesktop.contentType}
          alt={imageDesktop.title}
          sizes="(min-width:1090px) 1090px, 100vw"
        />
      </div>
    </div>
  </div>
)
