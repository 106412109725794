import Script from 'next/script'

export const Scripts = () => {
  return (
    <>
      {/* OneTrust */}
      {['production', 'preview'].includes(process.env.NEXT_PUBLIC_VERCEL_ENV) && (
        <>
          <Script
            id="onetrust-1"
            src={process.env.NEXT_PUBLIC_COOKIE_ONETRUST_RGPD_AUTOBLOCK}
            strategy="beforeInteractive"
          />
          <Script
            id="onetrust-2"
            src={`${process.env.NEXT_PUBLIC_COOKIE_ONETRUST_RGPD_SRC}`}
            data-domain-script={`${process.env.NEXT_PUBLIC_COOKIE_ONETRUST_RGPD_DOMAIN}`}
            strategy="beforeInteractive"
          />
          <Script id="onetrust-3">{`function OptanonWrapper() {}`}</Script>
        </>
      )}

      {/* Google Analytics */}
      <Script id="google-1" async defer src="https://www.googletagmanager.com/gtag/js" />
      <Script id="google-2">
        {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GTAG_ID}', {
            app_version: '${process.env.APP_VERSION}',
            app_name: '${process.env.NEXT_PUBLIC_APP_NAME}',
            custom_map: {
              dimension1: 'user_id',
            },
          })`}
      </Script>
      <noscript>
        <iframe
          title="gtag"
          src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTAG_ID}`}
          height="0"
          width="0"
        />
      </noscript>

      <Script id="facebook-3">
        {`!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;t.defer=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${process.env.NEXT_PUBLIC_FACEBOOK_BUSINESS_ID}');
          fbq('track', 'PageView');
        `}
      </Script>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: 'none' }}
          src={`https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FACEBOOK_BUSINESS_ID}&ev=PageView&noscript=1`}
          alt=""
        />
      </noscript>
    </>
  )
}
