import { useIntl } from 'react-intl'
import classnames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

export enum NotificationTypes {
  ERROR = 'ERROR',
  TEXT = 'TEXT',
  ACTION = 'ACTION',
}

export interface NotificationType {
  id: string
  type: keyof typeof NotificationTypes
  title: string
  description?: string
  href?: string
  actionTitle?: string
  values?: Record<string, string>
  duration?: number
  delay?: number
  timing?: number
  showNotif?: boolean
}

export interface NotificationProps {
  notification: NotificationType | null
  removeNotification: (param: string) => void
}

export const Notification = ({ notification, removeNotification }: NotificationProps) => {
  const { title, description, values, type, delay, duration } = notification || {}

  const { formatMessage: getI18nKey } = useIntl()

  const [showNotif, setShowNotif] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      setShowNotif(true)
      if (duration !== Infinity) {
        setTimeout(() => {
          setShowNotif(false)
          removeNotification(notification.id)
        }, duration)
      }
    }, delay)
  }, [notification.id, removeNotification, setShowNotif, delay, duration])

  switch (type) {
    case NotificationTypes.ERROR:
    case NotificationTypes.ACTION:
      return (
        <div
          className={classnames(
            'flex justify-center items-center opacity-0 invisible relative py-1 px-4 m-5 min-h-58 shadow rounded-lg',
            {
              hidden: !showNotif,
              'animate-notification': showNotif,
              'bg-heliotrope text-white': type === 'ACTION',
              'bg-watermelon text-white': type === 'ERROR',
            },
          )}
        >
          <div className="flex-1 max-w-md">
            <p className="text-base flex-1 text-white">{getI18nKey({ id: title }, values)}</p>
            {description && (
              <p className="text-base text-white">{getI18nKey({ id: description }, values)}</p>
            )}
          </div>
          {/* {actionTitle &&
            (href ? (
              <Link href={href}>
                <a className="text-white p-1">{getI18nKey({ id: actionTitle })}</a>
              </Link>
            ) : (
              <button
                type="button"
                className="text-white p-1"
                onClick={() => {
                  setShowNotif(false)
                  removeNotification(notification.id)
                }}
              >
                {getI18nKey({ id: actionTitle })}
              </button>
            ))} */}
        </div>
      )

    default:
      return null
  }
}
