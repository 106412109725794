import Link from 'next/link'
import { useMemo } from 'react'
import classnames from 'classnames'

import { Button } from '../atoms/button/Button'

import { BlockHighCms, CtaStripe, TextBlock } from '.'
import { computeAnalyticsTrackEvent } from 'src/utils/analytics'

export interface CatalogProgramsProps {
  title: string
  description: any
  programsCollection: ProgramsCollectionProps
  cta: any
}

interface ProgramsCollectionProps {
  items: ItemsFormatsProps[]
}

interface ItemsFormatsProps {
  title: string
  uuid: string
  coverHigh: any //AssetContentful
  format: {
    title: string
    slug: string
    icon: any //AssetContentful
    description: any
  }
}

export const CatalogPrograms = ({
  title,
  description,
  programsCollection,
  cta,
}: CatalogProgramsProps) => {
  const renderCta = useMemo(() => {
    switch (cta?.__typename) {
      case 'Cta':
        return (
          <Button
            className="m-auto"
            title={cta.label}
            href={cta.url}
            buttonType={cta.displayType}
            onClick={() => {
              if (cta.analytics) {
                computeAnalyticsTrackEvent(cta.analytics.eventName, cta.analytics.categorie, {
                  name: cta.analytics.label,
                })
              }
            }}
            scroll={!cta.url.includes('#')}
          >
            {cta.label}
          </Button>
        )

      case 'CtaStripe':
        return (
          <CtaStripe
            label={cta.label}
            priceId={cta.priceId}
            promoCode={cta.promoCode}
            trialPeriod={cta.trialPeriod}
          />
        )

      default:
        return null
    }
  }, [cta])

  return (
    <div>
      <div className="max-w-screen-lg m-auto">
        <TextBlock title={title} description={description} />
        <div className="grid grid-cols-2 gap-x-10 gap-y-10 px-5 py-10 md:grid-cols-4 md:gap-x-16 md:gap-y-14 md:px-5 lg:px-0 catalog">
          {programsCollection.items
            .map((item, key) => (
              <div
                className={classnames('w-36 md:w-auto md:max-w-44', {
                  'justify-self-end': key % 2 === 0,
                })}
                key={key}
              >
                <Link href={`/programs/${item.uuid}`} locale="fr">
                  <a>
                    <BlockHighCms
                      title={item.title}
                      showTitle
                      format={item.format.title}
                      image={item.coverHigh}
                    />
                  </a>
                </Link>
              </div>
            ))
            .slice(0, 8)}
        </div>
        <div className="w-max m-auto">{cta && renderCta}</div>
      </div>
    </div>
  )
}
