import { Fragment, useMemo } from 'react'

type ImageFormat = 'webp' | 'jpg' | 'png' | 'svg'

type OrientationSource = {
  portrait: string
  landscape: string
}

type IamgeProps = Omit<JSX.IntrinsicElements['img'], 'src'> & {
  imageClassName?: string
  src: string | OrientationSource
  contentType?: string
}

export const SRC_SET_SIZES = [110, 320, 640, 750, 828, 1080, 1280, 1920, 2048, 3840]

export const generateSrcSet = (src: string, format: ImageFormat): string =>
  format !== 'svg'
    ? SRC_SET_SIZES.map(size => `${src}?fm=${format}&w=${size} ${size}w`).join(', ')
    : src

const isOrientationSource = (source: string | OrientationSource): boolean =>
  typeof source !== 'string'

export const ImageCms = ({
  imageClassName = '',
  className = '',
  src,
  width,
  height,
  alt = 'Majelan',
  sizes = '100vw',
  contentType = 'image/jpg',
  ...rest
}: IamgeProps) => {
  const originalFormat: ImageFormat = useMemo(() => {
    switch (contentType) {
      case 'image/svg+xml':
        return 'svg'

      case 'image/png':
        return 'png'

      case 'image/jpg':
      case 'image/jpeg':
      default:
        return 'jpg'
    }
  }, [contentType])

  const hasOrientation = useMemo(() => isOrientationSource(src), [src])

  const defaultSrc = useMemo(
    () => (hasOrientation ? (src as OrientationSource).landscape : (src as string)),
    [hasOrientation, src],
  )

  return (
    <picture className={className}>
      {Object.entries(hasOrientation ? src : { src }).map(([orientation, source], key) => {
        if (!hasOrientation && originalFormat === 'svg')
          return <Fragment key={`source-srcset_${orientation}_webp_${key}`} />

        const media = [...[hasOrientation && `(orientation: ${orientation})`]]
          .filter(value => typeof value === 'string')
          .join(' and ')

        return (
          <Fragment key={`source-srcset_${orientation}_${originalFormat}_${key}`}>
            {originalFormat !== 'svg' && (
              <source
                type="image/webp"
                srcSet={generateSrcSet(source as string, 'webp')}
                sizes={sizes}
                key={`source-srcset_${orientation}_webp_${key}`}
                {...(media && { media })}
              />
            )}
            <source
              type={contentType}
              srcSet={generateSrcSet(source as string, originalFormat)}
              key={`source-srcset_${orientation}_${originalFormat}_${key}`}
              sizes={sizes}
              {...(media && { media })}
            />
          </Fragment>
        )
      })}
      <img
        className={imageClassName}
        src={defaultSrc}
        srcSet={generateSrcSet(defaultSrc, originalFormat)}
        width={width}
        height={height}
        alt={alt}
        sizes={sizes}
        loading="lazy"
        key="original"
        {...rest}
      />
    </picture>
  )
}
