import React from 'react'

import { ImageCms } from './ImageCms'

export interface BlockHighCmsProps {
  title: string
  showTitle?: boolean
  image: any //AssetContentful
  format?: string
}

export const BlockHighCms = ({ title, showTitle, image, format }: BlockHighCmsProps) => (
  <>
    <ImageCms
      src={image.url}
      className="block aspect-w-6 aspect-h-8"
      imageClassName="rounded-md"
      width={image.width}
      height={image.height}
      contentType={image.contentType}
      sizes="180px"
    />
    <div className="justify-start pt-3 pr-1 pb-1 pl-1">
      <div className="flex justify-start items-center">
        {!!format && <span className="text-cloud leading-normal font-medium">{format}</span>}
      </div>
      {showTitle && <p className="text-white leading-normal text-sm my-1 font-medium">{title}</p>}
    </div>
  </>
)
