import React from 'react'

import { BadgeCorner } from '../atoms/BadgeCorner'

import { CtaStripe, CtaStripeProps } from '../cms'

export interface productBlocksItems {
  title: string
  price: number
  tagline?: string
  description?: string
  benefits?: string[]
  disadvantages?: string[]
  ctaStripe: CtaStripeProps
  badge?: string
  hasBeenPremiumReplacement?: productBlocksItems
}

export interface SubscriptionCardProps {
  handleClick?: (price: any) => void
  product: productBlocksItems
}

export const SubscriptionCard = ({
  product: { title, price, tagline, description, benefits, disadvantages, ctaStripe, badge },
}: SubscriptionCardProps) => (
  <>
    <div className="relative p-1 rounded-2xl w-80 bg-majelan">
      <div className="bg-palombe rounded-xl p-8">
        {!!badge && <BadgeCorner title={badge} />}
        <p className="text-white text-lg my-3 font-bold">{title}</p>
        <div className="flex space-x-3">
          <h1>{price}</h1>
          <div>
            <p className="text-sm text-white">{tagline}</p>
            <p className="italic text-xs text-white">{description}</p>
          </div>
        </div>
        <hr className="border-cloud border-t-2 mt-2" />
        <ul className="my-8 space-y-5">
          {disadvantages?.map((disadvantage: string, elementKey: number) => (
            <li className="flex items-center space-x-3" key={`ListElements${elementKey}`}>
              <img className="w-5" src="/close.png" alt="" />
              <p className="text-lg text-white">{disadvantage}</p>
            </li>
          ))}
          {benefits?.map((benefit: string, index: number) => (
            <li className="flex items-center space-x-3" key={`ListElements${index}`}>
              <img className="w-5" src="/check-gradient.png" alt="" />
              <p className="text-lg text-white">{benefit}</p>
            </li>
          ))}
        </ul>
        <CtaStripe
          label={ctaStripe.label}
          priceId={ctaStripe.priceId}
          promoCode={ctaStripe.promoCode}
          trialPeriod={ctaStripe.trialPeriod}
          fullWidth
        />
      </div>
    </div>
  </>
)
