import React from 'react'
import Link from 'next/link'

import { TextDocument } from '.'

interface ItemsFormatsProps {
  title: string
  slug: string
  icon: {
    url: string
    title: string
    width: number
    height: number
    description: string
    size: number
  }
}

interface FormatsCollectionProps {
  items: ItemsFormatsProps[]
}

interface FormatBlockCmsProps {
  title: string
  description: any
  formatsCollection: FormatsCollectionProps
}

export const FormatsBlockCms = ({ title, description, formatsCollection }: FormatBlockCmsProps) => (
  <div>
    <div className="px-5 max-w-screen-lg m-auto">
      <div className="flex flex-col items-center md:flex-row md:ml-2 space-y-5 md:space-y-0 md:space-x-5 lg:space-x-20">
        <div className="md:w-1/2 lg:w-7/12">
          <h1 className="text-5xl mb-2 text-center md:text-left">{title}</h1>
          <TextDocument
            className="text-lg text-cloud font-sans text-center md:text-left"
            text={description.json}
          />
        </div>
        <div className="w-full md:w-1/2 lg:w-5/12 grid grid-cols-2 gap-x-9 gap-y-5 md:gap-x-5 md:gap-y-10 lg:gap-10">
          {formatsCollection.items.map((item: any, key: any) => (
            <Link key={key} href={`/catalog${item.slug}`}>
              <a className="rounded-md flex items-center justify-center border-2 border-solid border-stormyWheather bg-gradient-to-r from-shark  to-tuna p-6 lg:p-8">
                <img src={item.icon.url} alt="" className="w-5 md:w-7" />
                <p className="ml-2 uppercase text-xs md:text-sm">{item.title}</p>
              </a>
            </Link>
          ))}
        </div>
      </div>
    </div>
  </div>
)
