import React from 'react'

export interface IconProps {
  name: any | ''
  inversed?: boolean
  background?: boolean
  rounded?: boolean
  onClick?: () => void
  className?: string
}

export const Icon = ({ name, className = 'text-xl' }: IconProps) => (
  <div className="relative">
    <span className={`${className} relative maj-icon-${name}`} />
  </div>
)
