import classnames from 'classnames'
import NextLink from 'next/link'

import { LinkProps } from '../Link'

import { useLocale } from 'src/hooks/use-locale'
import { Icon } from '../Icon'

export enum ButtonType {
  PRIMARY = 'primary',
  NEUTRAL = 'neutral',
  DANGER = 'danger',
  TRANSPARENT = 'transparent',
  SECONDARY = 'secondary',
}

export interface ButtonProps {
  buttonType?: ButtonType | 'primary' | 'secondary' | 'transparent'
  title?: string
  onClick?: () => void
  icon?: any
  iconSize?: any
  href?: any
  children?: React.ReactNode
  loading?: boolean | string
  disabled?: boolean
  prefetch?: boolean
  type?: 'button' | 'submit'
  ['data-testid']?: string
  fullWidth?: boolean
  locale?: string | false
  className?: string
  scroll?: boolean
}

export const Button = ({
  href,
  title,
  onClick,
  icon,
  iconSize = '30',
  buttonType = ButtonType.PRIMARY,
  target = '_self',
  children,
  type = 'button',
  loading = false,
  prefetch = true,
  disabled,
  'data-testid': dataTestid,
  fullWidth = false,
  locale = false,
  className = '',
  ...props
}: ButtonProps & Pick<LinkProps, 'target'>) => {
  const { defaultLocale } = useLocale()

  const ButtonElement = (
    <button
      type={type}
      disabled={disabled}
      onClick={() => onClick?.()}
      {...props}
      className={classnames(
        'no-underline min-h-45 w-full flex items-center justify-center rounded-3xl hover:bg-opacity-80 leading-none font-medium text-center text-sm transition-colors',
        className,
        {
          'bg-heliotrope text-white': buttonType === 'primary',
          'bg-transparent border border-cloud border-solid text-cloud hover:bg-shark hover:border-opacity-50': [
            'secondary',
            'neutral',
          ].includes(buttonType),
          'cursor-not-allowed opacity-50': disabled,
          'px-0': buttonType === 'transparent',
          'px-3': fullWidth,
          'px-10': !fullWidth,
        },
      )}
    >
      {icon && <Icon name={icon} className="leading-none pr-2" />}
      {children}
    </button>
  )

  if (href) {
    return (
      <NextLink href={href} locale={locale || defaultLocale} {...props}>
        <a
          className={classnames(
            'no-underline min-h-45 w-auto flex flex-grow-0 items-center justify-center rounded-3xl hover:bg-opacity-80 leading-none font-medium text-center text-sm transition-colors',
            className,
            {
              'bg-heliotrope text-white': buttonType === 'primary',
              'bg-transparent border border-cloud border-solid text-cloud hover:bg-shark hover:border-opacity-50': [
                'secondary',
                'neutral',
              ].includes(buttonType),
              'px-0': buttonType === 'transparent',
              'px-3': fullWidth && buttonType !== 'transparent',
              'px-10': !fullWidth && buttonType !== 'transparent',
            },
          )}
          {...(props as any)}
          target={target}
        >
          {icon && <Icon name={icon} className="leading-none pr-2" />}
          {children}
        </a>
      </NextLink>
    )
  }

  return ButtonElement
}
