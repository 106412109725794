import React from 'react'

import { useNotification } from '../providers/NotificationProvider'
import { Notification, NotificationType } from '../atoms/notification/Notification'

export const NotificationsList = () => {
  const { notifications, removeNotification } = useNotification()

  return (
    <div className="flex flex-col justify-center items-center fixed mx-auto my-0 z-10 top-1/10 w-full">
      {notifications.map((item: NotificationType) => (
        <Notification
          notification={item}
          key={`notifications_${item.id}`}
          removeNotification={removeNotification}
        />
      ))}
    </div>
  )
}
