import { ImageCms } from './ImageCms'

type ImageFullWidthProps = {
  image: any //AssetContentful
}

export const ImageFullWidth = ({ image }: ImageFullWidthProps) => (
  <div>
    <div className="max-w-screen-lg m-auto">
      <ImageCms
        imageClassName="w-full lg:rounded-2xl"
        src={image.url}
        width={image.width}
        height={image.height}
        contentType={image.contentType}
        sizes="(min-width:1090px) 1090px, 100vw"
      />
    </div>
  </div>
)
