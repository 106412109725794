import React from 'react'
import classNames from 'classnames'

interface BadgeCornerProps {
  title: string
  gradient?: boolean
}

export const BadgeCorner = ({ title, gradient = true }: BadgeCornerProps) => (
  <div
    className={classNames(
      'flex items-center justify-center rounded-bl-lg absolute w-44 h-10 top-0 right-0 rounded-tr-2xl',
      {
        'bg-badge': gradient,
      },
    )}
  >
    <p className="font-bold text-tuna">{title}</p>
  </div>
)
