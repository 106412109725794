import classNames from 'classnames'
import { useRouter } from 'next/router'
import React, { PropsWithChildren } from 'react'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'

export interface LinkProps extends NextLinkProps {
  target?: '_self' | '_blank' | string
  rel?: string
  className?: string
}

export const Link = ({
  children,
  href,
  target = '_self',
  locale,
  className = '',
  ...props
}: PropsWithChildren<LinkProps>) => {
  const { defaultLocale } = useRouter()

  return (
    <NextLink href={href} locale={locale || defaultLocale} passHref {...props}>
      <a className={classNames(className, 'text-white')} {...(props as any)} target={target}>
        {children}
      </a>
    </NextLink>
  )
}
