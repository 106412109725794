import React, { PropsWithChildren } from 'react'

import { IntlProvider } from './IntlProvider'
// import { StripeProviders } from './StripeProvider'
import { NetworkProvider } from './NetworkProvider'
import { AuthProvider } from './AuthProvider'
import { NotificationProvider } from './NotificationProvider'
import { OfferProvider } from './OfferProvider'
// import { MiniPlayerProvider } from '../../providers/MiniPlayerProvider'

export interface AppProvidersProps {}

export const AppProviders = ({ children }: PropsWithChildren<AppProvidersProps>) => (
  <IntlProvider>
    <NotificationProvider>
      <NetworkProvider>
        <AuthProvider>
          <OfferProvider>{children}</OfferProvider>
        </AuthProvider>
      </NetworkProvider>
    </NotificationProvider>
  </IntlProvider>
)
